import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ServicesModule } from './services/services.module';
import { InstruccionesGuard } from './guards/instrucciones.guard';
import { ChangePasswordPageModule } from './change-password/change-password.module';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [InstruccionesGuard, AuthGuard]
  },
  {
    path: 'instrucciones',
    loadChildren: () => import('./instrucciones/instrucciones.module').then(m => m.InstruccionesPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./new-login/new-login.module').then(m => m.NewLoginPageModule),
    canActivate: [InstruccionesGuard, LoginGuard]
  },
  {
    path: 'codigo',
    loadChildren: () => import('./codigo/codigo.module').then(m => m.CodigoPageModule)
  },
  {
    path: 'pagos-publicos',
    loadChildren: () => import('./pagos-publicos/pagos-publicos.module').then(m => m.PagosPublicosPageModule)
  },
  {
    path: 'consultas',
    loadChildren: () => import('./consultas/consultas.module').then(m => m.ConsultasPageModule)
  },
  { path: 'beneficios', loadChildren: () => import('./beneficios/beneficios.module').then(m => m.BeneficiosPageModule) },
  { path: 'contactanos', loadChildren: () => import('./contactanos/contactanos.module').then(m => m.ContactanosPageModule) },
  {
    path: 'form-register-desktop',
    loadChildren: () => import('./form-register-desktop/form-register-desktop.module').then(m => m.FormRegisterDesktopPageModule)
  },
  { path: 'change-password', loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordPageModule) }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ServicesModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
