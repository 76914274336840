import { Injectable } from '@angular/core';
import { FileTransfer, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { AndroidPermissions, AndroidPermissionResponse } from '@awesome-cordova-plugins/android-permissions/ngx';

@Injectable({
  providedIn: 'root'
})
export class DownloadFilesService {

  fileTransfer: FileTransferObject = this.transfer.create();

  constructor(
    private transfer: FileTransfer,
    private file: File,
    private fileOpener: FileOpener,
    private platform: Platform,
    private androidPermissions: AndroidPermissions,
    private alertController: AlertController
  ) {
    console.log('init class download files service');
   }

  async download(urlFile, nameFile = 'file') {
    const alertLoading = await this.alertController.create({
      header: 'Descargando',
      message: 'Por favor espere...',
      backdropDismiss: false,
      cssClass: 'alert-loading'
    })
    this.checkAndroidPermission();
    alertLoading.present();
    console.log('urlFile :>> ', urlFile);
    let target = this.platform.is('ios') ? this.file.documentsDirectory : this.file.dataDirectory;
    this.fileTransfer.download(urlFile, target + `${nameFile}.pdf`).then((entry) => {
      let url = entry.toURL();
      console.log('entry.toURL() :>> ', entry.toURL());
      this.showFile(url);
    }, (error) => {
      console.log('error :>> ', error);
    }).finally(() => {
      alertLoading.dismiss();
    });
  }
  
  checkAndroidPermission(){
    if (this.platform.is('android')) {
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(
        result => {
          result.hasPermission ? console.log('Permission granted') : this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE);
        }
      )
    }
  }

  showFile(url: string) {
    console.log('url :>> ', url);
    this.fileOpener.open(url, 'application/pdf')
      .then(() => console.log('File is opened'))
      .catch(e => console.log('Error opening file', e));
  }
}
