import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NavigationEnd, Router } from '@angular/router';
import { TransactionLogs } from './interfaces';
import { EndpointService } from './services';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { Style, StatusBar } from '@capacitor/status-bar';
import {Capacitor} from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { environment } from 'src/environments/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { register } from 'swiper/element/bundle'; 
import { CheckResolutionService } from './services';
import { Subscription } from 'rxjs';

const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  private subscription: Subscription;
  transactionLogs: TransactionLogs = undefined;
  isDesktop = false;
  constructor(
    private endpointService: EndpointService,
    private storage: Storage,
    private router: Router,
    private ckeckResolution : CheckResolutionService,
  ) {
    this.isDesktop = this.ckeckResolution.isDesktop;
    this.subscription = this.ckeckResolution.resolution$.subscribe(
      (value) => (this.isDesktop = value)
    );
    this.initializeApp();
  }
  
  async ngOnInit() {
    await this.storage.create();
    this.router.events.subscribe(async val => {
      if (val instanceof NavigationEnd) {
        const loggedIn = this.router.url.search('/dashboard/') !== -1;
        let user;
        if (loggedIn) {
          user = await this.storage.get('login-service');
          user = user.datosBasicos.datosBasicosResponse;
        }
        this.storage.set('previousUrl', val.url);
        const descripcion = await {
          previousUrl: await this.storage.get('previousUrl'),
          currentUrl: val.url
        };
        this.transactionLogs = await {
          nombre: loggedIn ? `${user.nombres} ${user.apellidos}` : 'No ha iniciado sesión',
          tipoDocumento: loggedIn ? Number(user.tipoIdentificacionId) : 0,
          documento: loggedIn ? Number(user.identificacion) : 0,
          accion: 'Navegación entre módulos',
          descripcionAccion: `${JSON.stringify(descripcion)}`,
          estado: 200,
        };
        this.endpointService.saveTransactionLogs('private/TransactionalLog', this.transactionLogs)
          // tslint:disable-next-line: deprecation
          .subscribe(data => data);
      }
    });
    if (isPushNotificationsAvailable) {
      PushNotifications.requestPermissions().then(result => {
        if (result.receive) {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });

      PushNotifications.addListener('registration',
        (token: Token) => {
          localStorage.setItem('tokenPushNotifications', token.value);
          console.log('token.value :>> ', token.value);
        },
      );
      
      /*
      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotification) => {
          alert('Push received: ' + JSON.stringify(notification));
        },
      );

      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: PushNotificationActionPerformed) => alert('Push action performed: ' + JSON.stringify(notification))
      );
      */
    }
    const appInsights = new ApplicationInsights({ config: {
      instrumentationKey: `${environment.InstrumentationKeyApplicationInsights}`
      // `${environment.InstrumentationKeyApplicationInsights}`
      /* ...Other Configuration Options... */
    } });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }

  async initializeApp() {
    try {
//      await SplashScreen.hide();
      await StatusBar.setOverlaysWebView({ overlay: false });
      await StatusBar.setStyle({ style: Style.Light });
      StatusBar.setBackgroundColor({ color: '#ffffff' });
    } catch (error) {

    }
  }

  async saveDataLogin(data: any) {
    await localStorage.setItem('token', data.token);
    await this.storage.set(`canEnter`, true);
    await this.storage.set(`login-service`, data);
    await this.storage.set(`token`, data.token);
    return;
  }
}
