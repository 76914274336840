import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class GlobalAlertsService {

  buttons: Array<any> = [{
    text: 'cerrar',
    role: 'cancel',
    cssClass: 'btn-ok'
  }];

  constructor(public alertController: AlertController) { }

  async presentSuccessAlert(title= 'Operación exitosa', message = '', buttons = this.buttons) {
    const alert = await this.alertController.create({
      cssClass: 'my-success-alert',
      message: `<div style="width: 100%; text-align:center;">
                  <img src="assets/images/IconsNewMark/succesIconNewMark.svg" alt="">
                  <p class="title">${ title }</>
                  <p class="description">${ message }</p>
                </div>`,
      buttons
    });

    await alert.present();
  }

  async presentErrorAlert(title= 'Operación fallida', message = '', buttons = this.buttons) {
    const alert = await this.alertController.create({
      cssClass: 'my-error-alert',
      message: `<div style="width: 100%; text-align:center;">
                  <img src="assets/images/IconsNewMark/errorIconNewMark.svg" alt="">
                  <p class="title">${ title }</>
                  <p class="description">${ message }</p>
                </div>`,
      buttons
    });

    await alert.present();
  }

  async presentInfoAlert(title= 'Opppss', message = '', buttons = this.buttons) {
    const alert = await this.alertController.create({
      cssClass: 'my-info-alert',
      message: `<div style="width: 100%; text-align:center;">
                  <img src="assets/images/IconsNewMark/warningIconNewMark.svg" alt="">
                  <p class="title">${ title }</>
                  <p class="description">${ message }</p>
                </div>`,
      buttons
    });

    await alert.present();
  }
}
