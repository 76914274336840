import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { CheckResolutionService } from '../services';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstruccionesGuard  {
  private subscription: Subscription;
  isDesktop: boolean = false;

  constructor(
    private storage: Storage,
    private router: Router,
    private ckeckResolution : CheckResolutionService,
  ) { 
    this.isDesktop = this.ckeckResolution.isDesktop;
    this.subscription = this.ckeckResolution.resolution$.subscribe(
      (value) => (this.isDesktop = value)
    );
  }

  async canActivate() {
    let instrucciones: boolean = await this.storage.get('instrucciones');
    if (instrucciones || this.isDesktop)
      return true;
    else
      this.router.navigateByUrl('/instrucciones');
  }
}
