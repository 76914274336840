// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mock: "assets/mocks",
  storageCMS: "https://storagelaravelcmsweb.blob.core.windows.net/storage",
  neoValidador: "https://neovalidador.azurewebsites.net/api/validateEmail",

  // Produccion

  apiUrl: "https://aspnetcorepublicapi.azurewebsites.net/api/v1", // PROD
  webPublic: "https://www.chevyplan.com.co",
  apiHIS: "https://www.chevynet.com.co:8014/api",
  apiCertificate: "https://www.chevynet.com.co:8024/api/",
  urlRespuesta: "https://www.chevyplan.com.co/servicios_online/resultado_pago",
  urlConfirmacionPayU:
    "https://zonaclientes.chevyplan.com.co/Publico/ConfirmacionPago",
  apiProximaCeremonia: "https://www.chevynet.com.co:8014/api/",
  urlCases: "https://aspnetcorepublicapi.azurewebsites.net/api/LandingPage/",
  urlDetallePago: "https://www.chevynet.com.co:9099/api/",
  urlAndiasistencia: "https://andiasistencias.chevyplan.com.co/#/login",
  urlTramitesMicroservice: 'https://crm-tramitesdevoluciones.azurewebsites.net/api/',
  InstrumentationKeyApplicationInsights:'731fbe90-3fb6-4362-8eb7-be6b55dc772c',

  //apiUrl: 'http://localhost:5001/api/v1',
  // Develop
 // apiUrl: 'https://aspnetcorepublicapi-aspnercorepublicapi-pruebas.azurewebsites.net/api/v1',
  //apiUrl: 'https://localhost:5001/api/v1',
  // webPublic: 'https://webangular-pruebasv3.azurewebsites.net',
  // apiHIS: 'https://www.chevynet.com.co:9016/api',
  // apiCertificate: 'https://www.chevynet.com.co:9027/api',
  // urlRespuesta: 'https://webangular-webangularpruebas.azurewebsites.net/servicios_online/resultado_pago',
  // urlConfirmacionPayU: 'https://www.chevynet.com.co:9030/Publico/ConfirmacionPago',
  // apiProximaCeremonia: 'https://www.chevynet.com.co:9016/api/',
  // urlDetallePago: 'https://www.chevynet.com.co:9099/api/',
  // urlCases: "https://aspnetcorepublicapi-aspnercorepublicapi-pruebas.azurewebsites.net/api/LandingPage/",
  // urlAndiasistencia: 'https://d1zdnoj8iy9uu5.cloudfront.net/#/login',
  // urlTramitesMicroservice: 'https://crm-tramitesdevoluciones.azurewebsites.net/api/',
  //urlTramitesMicroservice: 'https://localhost:7118/api/',
  //InstrumentationKeyApplicationInsights:'ponereldepruebas',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
