import {Injectable} from '@angular/core';
import { Router } from '@angular/router';
import {Storage} from '@ionic/storage';
import { CheckResolutionService } from '../services';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoginGuard  {
    private subscription: Subscription;
    isDesktop = false;
    canEnter = false;

    constructor(
        private router: Router, 
        private storage: Storage, 
        private ckeckResolution : CheckResolutionService) { 
            this.isDesktop = this.ckeckResolution.isDesktop;
            this.subscription = this.ckeckResolution.resolution$.subscribe(
            (value) => (this.isDesktop = value)
            );
        }

    async canActivate() {
        const crossDomain = JSON.parse(localStorage.getItem('cross-domain'));
        if (crossDomain) {
            const data = await crossDomain.data;
            if (data.datosBasicos && data.datosBasicos.datosBasicosResponse) {
                data.document = data.datosBasicos.datosBasicosResponse.identificacion;
            }
            await this.saveDataLogin(data);
            await this.router.navigate(['/dashboard/home']);
        } else {
            return true;
        }
    }

    async saveDataLogin(data: any) {
        localStorage.setItem('token', data.token);
        await this.storage.set(`canEnter`, true);
        await this.storage.set(`login-service`, data);
        await this.storage.set(`token`, data.token);
    }
}
