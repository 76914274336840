import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Storage } from "@ionic/storage";

@Injectable({
  providedIn: "root"
})
export class AuthGuard  {
  constructor(private router: Router, private storage: Storage) {}

  canEnter: boolean = false;

  async canActivate() {
    this.canEnter = await this.storage.get("canEnter");
    if (this.canEnter) return true;
    else this.router.navigateByUrl("/login");
  }
}
