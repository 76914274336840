import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TransactionLogs } from 'src/app/interfaces';
import { Platform } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class EndpointService {

  constructor(
    private http: HttpClient,
    private platform: Platform
  ) { }

  private getUrlApi(url) {
    return `${environment.apiUrl}/${url}`;
  }

  get(url, options?): Observable<any> {
    return this.http.get(this.getUrlApi(url), options);
  }

  getMock(url, options?): Observable<any> {
    return this.http.get(`${environment.mock}/${url}`, options);
  }

  postNeovalidador(body, options?): Observable<any> {
    return this.http.post(`${environment.neoValidador}`, body,  options);
  }

  post(url, body, options?): Observable<any> {
    return this.http.post(this.getUrlApi(url), body, options);
  }

  put(url, body, options?): Observable<any> {
    return this.http.put(this.getUrlApi(url), body, options);
  }

  delete(url, options?): Observable<any> {
    return this.http.delete(this.getUrlApi(url), options);
  }

  getFullUrl(url, options?): Observable<any> {
    return this.http.get(url, options);
  }

  saveTransactionLogs(url = 'private/TransactionalLog', body: TransactionLogs, options?): Observable<any> {
    body.plataforma = this.getPlatform();
    body.fecha = this.getDate(new Date());
    return this.http.post(this.getUrlApi(url), body, options);
  }

  getPlatform() {
    let platform = '';
    if (this.platform.is('desktop')) {
      platform = 'ZONA CLIENTES WEB';
    } else if (this.platform.is('mobile')) {
      if (this.platform.is('android')) {
        platform = 'ZONA CLIENTES ANDROID';
        if (this.platform.is('mobileweb')) {
          platform = 'ZONA CLIENTES NAVEGADOR MOVIL ANDROID';
        }
      };
      if (this.platform.is('ios')) {
        platform = 'ZONA CLIENTES IOS';
        if (this.platform.is('mobileweb')) {
          platform = 'ZONA CLIENTES NAVEGADOR MOVIL IOS';
        }
      };
    } else {
      platform = 'Plataforma no detectada';
    }
    return platform;
  };

  getDate(date: Date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().padStart(4, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  saveDevolucionLogs(url = 'private/TransactionalLog/LogDevoluciones', body: TransactionLogs, options?): Observable<any> {
    body.plataforma = this.getPlatform();
    body.fecha = this.getDate(new Date());
    return this.http.post(this.getUrlApi(url), body, options);
  }
}
