import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './services/HttpInterceptor/interceptor.service';
// Interceptors

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { NetworkInterface } from '@awesome-cordova-plugins/network-interface/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';

import { CountdownModule } from 'ngx-countdown';
// FireBase Notifications
// import { FCM } from '@ionic-native/fcm/ngx';
// import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      innerHTMLTemplatesEnabled: true
    }),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    CountdownModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    InAppBrowser,
    NetworkInterface,
    AndroidPermissions
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
