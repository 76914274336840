import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { Router, NavigationExtras } from '@angular/router';
import { Storage } from '@ionic/storage';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { EndpointService } from '../endpoint/endpoint.service';
import { CheckResolutionService } from '../checkResolution/check-resolution.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  private subscription: Subscription;
  isDesktop = false;
  versionNumber = undefined;
  visibleAlert = false;
  constructor(
    private alertController: AlertController,
    private endpointService: EndpointService,
    private storage: Storage,
    private platform: Platform,
    private appVersion: AppVersion,
    private navCtrl: NavController,
    private ckeckResolution : CheckResolutionService,
  ) {
    this.isDesktop = this.ckeckResolution.isDesktop;
    this.subscription = this.ckeckResolution.resolution$.subscribe(
      (value) => (this.isDesktop = value)
    );

    this.appVersion.getVersionNumber().then(value => this.versionNumber = value).catch(err => err)
  }

  async showAlert(options) {
    const alert = await this.alertController.create({
      header: options.header,
      subHeader: options.subHeader,
      message: options.message,
      buttons: options.buttons,
      cssClass: options.cssClass,
    });
    if (this.visibleAlert) return
    this.visibleAlert = true;
    await alert.present();
    alert.onDidDismiss().then(() => {
      this.visibleAlert = false;
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem('token');

    let request = req;


    if (token) {
      request = req.clone({
        setHeaders: {
          authorization: `${token}`,
          usuario: `${this.platform.is('desktop') ? 'WEB' : 'APP'}`,
          plataforma: `${this.platform.is('desktop') ? 'WEB' : this.platform.is('android') ? 'android' : 'ios'}`,
          version: `${this.platform.is('desktop') ? '4.0.0' : this.versionNumber ? this.versionNumber : '1.0.1'}`
        },
      });
    }
    else {
      request = req.clone({
        setHeaders: {
          usuario: `${this.platform.is('desktop') ? 'WEB' : 'APP'}`,
          plataforma: `${this.platform.is('desktop') ? 'WEB' : this.platform.is('android') ? 'android' : 'ios'}`,
          version: `${this.platform.is('desktop') ? '4.0.0' : this.versionNumber ? this.versionNumber : '1.0.1'}`
        },
      });
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        let loginInformation: any = localStorage.getItem('login-service');
        console.log('err :>> ', err);
        if (loginInformation) {
          loginInformation = JSON.parse(loginInformation).datosBasicos.datosBasicosResponse;
        }

        const transactionLogs = {
          nombre: loginInformation ? `${loginInformation.nombres} ${loginInformation.apellidos}` : 'No ha iniciado sesión',
          crmId: "",
          tipoDocumento: loginInformation ? Number(loginInformation.tipoIdentificacionId) : 0,
          documento: loginInformation ? Number(loginInformation.identificacion) : 0,
          accion: 'Error en el interceptor',
          descripcionAccion: `${JSON.stringify({
            error: err.error,
            message: err.message,
            name: err.name,
            statusText: err.statusText,
            url: err.url
          })}`,
          AceptacionTerminos: 0,
          estado: err.status,
          version: this.versionNumber ? this.versionNumber : '4.0.0',
        };

        this.endpointService.saveTransactionLogs('private/TransactionalLog',transactionLogs)
          .subscribe(x => console.log('x :>> ', x));

          // Save devolucion logs
        this.endpointService.saveDevolucionLogs('private/TransactionalLog/LogDevoluciones', transactionLogs)
        .subscribe(x => console.log('Devolucion log saved:', x));

        if (!err.error.Message && err.status === 400) {
          err.error.Message = 'En este momento no es posible procesar su solicitud.';
        }
        if (!err.error.Message && err.status === 401) {
          err.error.Message =
            'Tu sesión ha expirado, por favor inicia sesión nuevamente';
        }
        if (!err.error.Message && err.status === 423) {
          err.error.Message =
            'Tu sesión ha expirado, por favor inicia sesión nuevamente';
        } else if (err.status === 500) {
          // tslint:disable-next-line:max-line-length
          err.error.Message =
            'Ha ocurrido un error en el servidor, por favor contacte a un asesor o intente nuevamente en un momento';
        }
        // tslint:disable-next-line:max-line-length triple-equals
        if (
          (err.status === 422 || err.status === 425 || err.status === 423) &&
          (err.error.Message === 'No se ha encontrado usuario' ||
            err.error.Message ===
            'Correo ingresado no coincide con información base' ||
            'Usuario no encontrado' ||
            err.error.Message === 'contraseña no es valida')
        ) {
          return throwError(err);
        }
        let mensaje = 'Se estan presentando problemas de comunicación, estamos trabajando para solucionar el inconveniente.';
        if (err.error.Message !== "" && err.error.Message !== undefined && err.error.Message !== null) {
          mensaje = err.error.Message;
        }
        if (err.error.message !== "" && err.error.message !== undefined && err.error.message !== null) {
          mensaje = err.error.message;
        }
        const options = {
          cssClass: 'register-messages-425',
          message: `<div style="width: 100%;"><img src="assets/images/IconsNewMark/warningIconNewMark.svg"><p class="label_425"><ion-label>¡LO SENTIMOS!</ion-label></p><p class="description">${mensaje}</p></div>`,
          buttons: [{
            text: "CERRAR",
            cssClass: 'btn-confirm',
          }]
        };
        console.log(options);
        this.showAlert(options);

        if (err.status === 401 || err.status === 422 || err.status === 411) {
          this.storage.clear();
          this.storage.set('instrucciones', true);
          const navigationExtras: NavigationExtras = {
            state: {
              logout: true,
            },
          };
          this.navCtrl.navigateRoot('/login', navigationExtras);
        }

        return throwError(err);
      })
    );
  }
}
