import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { EndpointService } from '.';
import { CheckResolutionService } from '.';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';


@NgModule({
  imports: [HttpClientModule],
  providers: [
    EndpointService,
    CheckResolutionService,
    FileTransfer,
    File,
    FileOpener,
    AppVersion
    // PreviewAnyFile
    // DocumentViewer
  ]
})
export class ServicesModule { }
