import { Injectable} from '@angular/core';
import { resolution } from 'src/app/utils/helper-items';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckResolutionService {
  private resolutionSource = new Subject<boolean>();
  resolution$ = this.resolutionSource.asObservable();
  isDesktop: boolean = false;

  constructor() { 
    this.isDesktop = window.innerWidth > resolution;
    this.handleResizeEvent();
  }

  private handleResizeEvent() {
    window.addEventListener('resize', () => {
      this.isDesktop = window.innerWidth > resolution;
      this.resolutionSource.next(this.isDesktop);
    });
  }

}
