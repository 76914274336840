const months: Array<any> = [
    { name: "Enero", value: "01" },
    { name: "Febrero", value: "02" },
    { name: "Marzo", value: "03" },
    { name: "Abril", value: "04" },
    { name: "Mayo", value: "05" },
    { name: "Junio", value: "06" },
    { name: "Julio", value: "07" },
    { name: "Agosto", value: "08" },
    { name: "Septiembre", value: "09" },
    { name: "Octubre", value: "10" },
    { name: "Noviembre", value: "11" },
    { name: "Diciembre", value: "12" }
];

const years: Array<any> = [
    { name: "2022", value: "2022" },
    { name: "2021", value: "2021" },
    { name: "2020", value: "2020" },
    { name: "2019", value: "2019" },
    { name: "2018", value: "2018" },
    { name: "2017", value: "2017" },
    { name: "2016", value: "2016" },
    { name: "2015", value: "2015" },
    { name: "2014", value: "2014" },
    { name: "2013", value: "2013" },
];

const types: Array<any> = [
    { name: "Contrato", value: "contrato" },
    { name: "Cupo", value: "cupo" },
    { name: "Documento de Identidad", value: "documento" },
  ] 

const resolution: number = 991;

export { months, years, types, resolution };